// switcherr
.theme-switcher-label {
    cursor: pointer;
    position: fixed;
    right: 30px;
    top: 30px;
    z-index: 99;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #4b4b4b;
    border-left: 0;
    transition: 0.3s;
    transform: translateY(-30%);
    &:hover {
        background-color: #656565;
    }

    @media (max-width: 1199px) {
        width: 45px;
        height: 45px;
        z-index: 99;
    }

    .switch-handle {
        border-radius: 50%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.3s ease-in-out;

        i {
            position: absolute;
            font-size: 28px;
            transition: 0.3s;
            left: 1px;
            top: -1px;
        }
    }

    .theme-switcher {
        display: none;
    }
    .theme-switcher-image{
        width: 24px;
        height: 24px;
    }
    .light-text {
        color: #000;
    }
    .dark-text {
        color: #666;
    }
    &.active {
        background-color: #eee;
        .light-text {
            visibility: hidden;
            opacity: 0;
        }
        .dark-text {
            visibility: visible;
            opacity: 1;
        }
    }
    .dark-text {
        visibility: hidden;
        opacity: 0;
    }
    input {
        cursor: pointer;
    }
}
